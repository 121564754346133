import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';

const PostSection = styled.section`
	padding: .5rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 2rem 0;
	}
`;

const PostContainer = styled.div`
	display: flex;
	flex-direction: column;

	max-width: 680px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
`;

const PostMainImage = styled.div`
	margin: .5rem auto;
	display: block;
	max-width: 680px;
	width: 100%;
	padding: 0;
	box-sizing: border-box;
`;

const PostMeta = styled.div`
	text-align: left;
	max-width: 680px;
	margin: 0 auto 1rem auto;
	width: 100%;
	padding: 0 1rem;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 0;
	}
	box-sizing: border-box;
`;

const PostTitle = styled.h3`
	margin-bottom: .25rem;
	line-height: 1.2em;

	margin-top: 0;
`;

const PostDate = styled.span`
	margin: .5rem auto 0 auto;
	padding: 0 1rem;
	display: block;
	color: rgba(61, 61, 61, .5);
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 0;
	}
	text-align: left;
	max-width: 680px;
	width: 100%;
	box-sizing: border-box;
`;

const TagContainer = styled.div`
	justify-content: left;
	display: flex;
	flex-wrap: wrap;

	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const PostBody = styled.div`
	max-width: 680px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	padding: 0 1rem 0 1rem;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 0;
	}

	img {
		width: 100%;
		box-sizing: border-box;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

const PreviousLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;

  text-decoration: none;
  color: ${cwTheme.black};
  margin-right: 1rem;
  font-weight: 700;
  
  max-width: 300px;
  text-align: right;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  h6{
	   margin-top: 0;
	   font-weight: 600;
  }

  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
  
`;

const NextLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
 
  text-decoration: none;
  color: ${cwTheme.black};
  margin-left: 1rem;
  font-weight: 700;
  
  text-align: left;
  max-width: 300px;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  display: flex;
  flex-direction: column;
  align-content: space-between;
  h6{
	margin-top: 0;
	font-weight: 600;
  }
  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
	
`;

const PostLinks = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
`;

const BlogHomeLink = styled(Link)`
	color: ${cwTheme.secondaryBlue};
	text-decoration: none;
	
	
	&:hover {
		color: ${cwTheme.secondaryHover};
	}
	h6{
		margin-top: 0;
		font-weight: 500;
		letter-spacing: 1.5px;
		margin-bottom: 1rem;
	}
`;

export default ({ data, pageContext }) => {
	const post = data.contentfulPost;

	const previous = pageContext.prev;
	const next = pageContext.next;
	const { basePath } = pageContext;

	const { title, body, publishDate } = data.contentfulPost;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={body.childMarkdownRemark.excerpt} />
				<PostSection>
					<PostContainer>
						<PostMeta>
							<BlogHomeLink to={'/blog'}>
								<h6>THE MINDFUL READER</h6>
							</BlogHomeLink>
							<PostTitle>{title}</PostTitle>

							<TagContainer>
								{post.tags == null ? (
									''
								) : (
										post.tags.map((postTag) => (
											<Link key={'pill' + postTag.id} to={'/blog/topic/' + postTag.slug}>
												{postTag.title}
											</Link>
										))
									)}
							</TagContainer>
						</PostMeta>
						<PostMainImage>
							<Img backgroundColor={cwTheme.lightBlue} sizes={post.mainImage.fluid} />
						</PostMainImage>
						<PostDate>{publishDate}</PostDate>
						<PostBody dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
						<hr></hr>
						<PostLinks>
							{previous ? (
								<PreviousLink to={`${basePath}/${previous.slug}/`}>
									<span>&#8592; Previous</span>
									<h6>{previous.title}</h6>
								</PreviousLink>
							) : (
									<PreviousLink />
								)}
							{next ? (
								<NextLink to={`${basePath}/${next.slug}/`}>
									<span>Next &#8594;</span>
									<h6>{next.title}</h6>
								</NextLink>
							) : (
									<NextLink />
								)}
						</PostLinks>
					</PostContainer>
				</PostSection>

				<SignUpForm header={'Enjoyed this post? Receive the next one in your inbox!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!) {
		contentfulPost(slug: { eq: $slug }) {
			body {
				childMarkdownRemark {
					html
					excerpt
					timeToRead
				}
			}
			publishDate(formatString: "MMMM DD, YYYY")
			title
			tags {
				title
				slug
			}
			mainImage {
				fluid(maxWidth: 1200, maxHeight: 700, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
	}
`;
